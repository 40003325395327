import React, { useEffect, lazy, Suspense, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import ModalsProvider from '../contexts/Modals'
import { useUserInfo } from '@/store/useUserInfo'
import { useMediaInfo } from '@/store/useMediaInfo'
import styles from './App.module.less'
import { getJsonData, isBrowser, parseCookie } from '@/utils/utils'
import { useEnvInfo } from '@/store/useEnvInfo'
import useBridge from '@/hooks/useBridge'
import Footer from '@/components/Footer'
import { taInit } from '@/common/thinkingdata'
import { getSessionItem, setSessionItem } from '@/common/storage'
import { WalletApi } from '@/services/wallet'
import { errorNotice } from '@/common/tip'
import { useIdols } from '@/store/useIdols'
import { VOTE_DATA } from '@/constants/vote'
import GlobalBroadcast from '@/components/GlobalBroadcast'

const LazyTask = lazy(() => import('./Task'))
const LazySystemError = lazy(() => import('./SystemError'))
const LazyAboutUs = lazy(() => import('./About'))
const LazyPrivacyPolicy = lazy(() => import('./PrivacyPolicy'))
const LazyCSAEPolicy = lazy(() => import('./CSAEPolicy'))
const LazyUseTerms = lazy(() => import('./UseTerms'))
const LazyLogoff = lazy(() => import('./Logoff'))
const LazyRule = lazy(() => import('./Rule'))
const LazySearchIdol = lazy(() => import('./SearchIdol'))
const LazySupportRecord = lazy(() => import('./SupportRecord'))
// const LazyVoteActivity = lazy(() => import('./VoteActivity'))
// const LazyHome = lazy(() => import('./Home'))
const LazyHomeRanking = lazy(() => import('./HomeRanking'))
const LazyHomeRankingIntro = lazy(() => import('./HomeRanking/Intro'))
const LazyVideoRules = lazy(() => import('./VideoRules'))
const LazyRedEnvelopes = lazy(() => import('./RedEnvelopes'))
const LazyEventSummary = lazy(() => import('./RedEnvelopes/EventSummary'))
const LazyLive = lazy(() => import('./Live'))
const LazyAIAvatarRule = lazy(() => import('./AIAvatarRule'))
const LazyBest7 = lazy(() => import('./Best7'))
const LazyBest7VoteRecords = lazy(() => import('./Best7VoteRecords'))
const LazyFeatureFanQuestionRule = lazy(
  () => import('./FeatureFan/QuestionRule')
)
const LazyFeatureFanRule = lazy(() => import('./FeatureFan/Rule'))
const LazyAgentLiveRoom = lazy(() => import('./agentLiveRoom'))

async function getPublicKey() {
  const res = await WalletApi.getPublicKey()
  if (res.data.code === 0) {
    setSessionItem('PublicKey', res.data.data.publicKey)
  } else {
    errorNotice(res.data.message)
  }
}

const App: React.FC = () => {
  const { userInfo, save: saveUserInfo } = useUserInfo()
  const { save: saveEnvInfo } = useEnvInfo()
  const { resetMediaInfo, fetchMediaInfo } = useMediaInfo()
  const { getUserInfo, getEnvInfo } = useBridge()

  const { getIdols } = useIdols()

  const [isAppDataReady, setIsAppDataReady] = useState(() => {
    return isBrowser()
  })

  useEffect(() => {
    if (!userInfo) {
      resetMediaInfo()
    } else {
      fetchMediaInfo(userInfo.accountId)
    }
  }, [userInfo?.accountId])

  let count = 0
  function callback() {
    count++
    if (count === 2) {
      setIsAppDataReady(true)
    }
  }

  useEffect(() => {
    const cookies = parseCookie(document.cookie)
    const envInfoCookie = cookies['getEnvInfo']
    // android 会将信息放到cookie中，ios需要调方法获取
    if (envInfoCookie) {
      saveUserInfo(getJsonData(cookies['getUserInfo']))
      saveEnvInfo(getJsonData(envInfoCookie))
      setIsAppDataReady(true)
    } else {
      getUserInfo(callback)
      getEnvInfo(callback)
    }
  }, [])

  function init() {
    taInit()
    // 获取公钥
    if (!getSessionItem('PublicKey')) {
      getPublicKey()
    }

    // 获取idol列表
    // getIdols(VOTE_DATA.activityId)
  }

  useEffect(init, [])

  return (
    <div className={styles.app}>
      <ModalsProvider>
        <HashRouter>
          <div className={styles.layout}>
            <GlobalBroadcast />
            <Suspense>
              {isAppDataReady && (
                <Switch>
                  <Route path="/" component={LazySystemError} exact />
                  <Route path="/activity" component={LazyRedEnvelopes} exact />
                  <Route path="/best7" component={LazyBest7} exact />
                  <Route
                    path="/best7-vote-records"
                    component={LazyBest7VoteRecords}
                    exact
                  />
                  <Route path="/live" component={LazyLive} exact />
                  <Route
                    path="/event-summary"
                    component={LazyEventSummary}
                    exact
                  />
                  <Route path="/task" component={LazyTask} exact />
                  <Route path="/about-us" component={LazyAboutUs} exact />
                  <Route
                    path="/privacy-policy"
                    component={LazyPrivacyPolicy}
                    exact
                  />
                  <Route path="/csae-policy" component={LazyCSAEPolicy} exact />
                  <Route path="/use-terms" component={LazyUseTerms} exact />
                  <Route path="/logoff" component={LazyLogoff} exact />
                  <Route
                    path="/vote-activity"
                    component={LazyHomeRanking}
                    exact
                  />
                  <Route
                    path="/ranking-intro"
                    component={LazyHomeRankingIntro}
                    exact
                  />
                  {/* <Route path="/vote-page" component={LazyVoteActivity} exact /> */}
                  <Route path="/rule" component={LazyRule} exact />
                  <Route path="/search-idol" component={LazySearchIdol} exact />
                  <Route
                    path="/support-record"
                    component={LazySupportRecord}
                    exact
                  />
                  <Route path="/video-rules" component={LazyVideoRules} exact />
                  <Route
                    path="/ai-avatar-rules"
                    component={LazyAIAvatarRule}
                    exact
                  />
                  <Route
                    path="/feature-fan-question-rule"
                    component={LazyFeatureFanQuestionRule}
                    exact
                  />
                  <Route
                    path="/feature-fan-rule"
                    component={LazyFeatureFanRule}
                    exact
                  />
                  <Route
                    path="/agent-live-room"
                    component={LazyAgentLiveRoom}
                    exact
                  />
                </Switch>
              )}
            </Suspense>
            <Footer />
          </div>
        </HashRouter>
      </ModalsProvider>
    </div>
  )
}

export default App
