import { service } from '../http'
import { paramAddChain } from '@/utils/utils'
import {
  LoginWeb3Params,
  LoginWeb2Params,
  UserInfo,
  RegisterParams,
  PublicKey,
  ForgetPasswordParams,
  LogoutParams,
  CheckEmailRegisterParams,
  UserAsset,
  NftExchangeRecord,
  ReceivedPointRecord,
  TransferTicketRecord,
  PointExchangeList,
  ExchangeTicketParams,
  ExchangeTicketData,
  VoteForIdolParams,
  VoteForIdolData,
  VoteRecord,
  UserInviteRecord,
  PendingPoints,
  UserAndAllPointRank,
  GetUserInfoParams,
  UpdateAccountInfoParams,
  UpdateAvatarParams,
  UpdateAvatarData,
  UpdateAccountPasswordParams,
  UpdateWeb3EmailParams,
  UpdateAccountEmailParams,
  HKTicket,
  GetPointToNftListParams,
  GetOrderListParams,
  BuyTicketParams,
  TicketBuyRecord,
  ActivityParticipateParams,
  ActivityAvailableBalanceParams,
  PageParams,
  RankingListResult,
  VoteRecordParams
} from './model'
import { PromiseResponse, BaseParams, PromiseListResponse } from '../common'
import { RecaptchaActions, getGoogleRecaptchaToken } from '@/common/login'
import { errorNotice } from '@/common/tip'

const BASE_URL = '/wallet/api/v1'

export class WalletApi {
  // Web3登录
  static async mutateLoginWeb3(
    params: LoginWeb3Params
  ): PromiseResponse<UserInfo> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/user/metaMaskLogin`, data)
  }
  // 获取验证码
  static async getCodeFromEmail(
    email: string,
    type = `1`
  ): PromiseResponse<void> {
    let token = ''
    try {
      token = await getGoogleRecaptchaToken(RecaptchaActions.sendEmail)
    } catch (error: any) {
      errorNotice(error)
    }
    const params = new URLSearchParams()
    params.append('email', email)
    params.append('type', type)
    params.append('googleRecaptchaToken', token ?? '')
    params.append('googleActionType', RecaptchaActions.sendEmail)
    return service.get(`${BASE_URL}/user/sendEmail?${params.toString()}`)
  }
  // 注册_web2
  static async registerWeb2(params: RegisterParams): PromiseResponse<UserInfo> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/user/register`, data)
  }
  // 登录_web2
  static async loginWeb2(params: LoginWeb2Params): PromiseResponse<UserInfo> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/user/login`, data)
  }
  // 加密公钥
  static async getPublicKey(): PromiseResponse<PublicKey> {
    return service.post(`${BASE_URL}/user/getPublicKey`)
  }
  // 忘记密码
  static async forgetPassword(
    params: ForgetPasswordParams
  ): PromiseResponse<void> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/user/forgotPassword`, data)
  }
  // 退出登录
  static async logOut(params: LogoutParams): PromiseResponse<void> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/user/logout`, data)
  }
  // 验证邮箱是否注册
  static async checkMailRegi(
    params: CheckEmailRegisterParams
  ): PromiseResponse<boolean> {
    return service.post(`${BASE_URL}/userExpand/checkMailRegister`, params)
  }
  // 获取用户资产
  static async getUserAsset(accountId: string): PromiseResponse<UserAsset[]> {
    return service.post(`${BASE_URL}/user/getAssetDetail`, { accountId })
  }
  // 积分兑换记录
  static async queryNftExchangeRecord(
    params: BaseParams
  ): PromiseResponse<NftExchangeRecord[]> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/userExpand/getNftExchangeRecord`, data)
  }
  // 积分明细
  static async queryPointRecords(
    params: BaseParams
  ): PromiseResponse<ReceivedPointRecord[]> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/userExpand/getReceivedPointRecords`, data)
  }
  // 获取用户的Voting NFT转账记录
  static async queryTransferTicketRecords(
    params: BaseParams
  ): PromiseResponse<TransferTicketRecord[]> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/userExpand/transferTicketRecords`, data)
  }
  // 获取积分兑换列表
  static async queryPointExchangeList(
    params: BaseParams
  ): PromiseResponse<PointExchangeList> {
    return service.post(`${BASE_URL}/pointsMall/getPointExchangeList`, params)
  }
  // 兑换Voting NFT
  static async mutateExchangeTicket(
    params: ExchangeTicketParams
  ): PromiseResponse<ExchangeTicketData> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/pointsMall/exchangeTicket`, data)
  }
  // 转账Voting NFT
  static async mutateTransferVotes(params?: any) {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/pointsMall/transferVotes`, data)
  }
  // 获取免费测试币
  static async queryFreeTestCoin(params?: any) {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/userExpand/getFreeTestCoin`, data)
  }
  // 偶像投票
  static async mutateVoteForIdol(
    params: VoteForIdolParams
  ): PromiseResponse<VoteForIdolData> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/ticketVote/voteForIdol`, data)
  }
  // 获取用户投票记录
  static async queryVoteRecords(
    params: VoteRecordParams
  ): PromiseListResponse<{ list: VoteRecord[] }> {
    return service.post(`${BASE_URL}/userExpand/getVoteRecords`, params)
  }
  // 邀请记录
  static async queryInviteRecords(
    params: BaseParams
  ): PromiseResponse<UserInviteRecord[]> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/userExpand/userInviteRecords `, data)
  }
  // 邀请任务积分信息
  static async queryInviteTaskPoints(
    params: BaseParams
  ): PromiseResponse<PendingPoints> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/userExpand/getPendingPoints `, data)
  }
  // 获取用户的积分排名和和全部前50排名
  static async queryUserPointRank(
    params: BaseParams
  ): PromiseResponse<UserAndAllPointRank> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/userExpand/getUserAndAllPointRank `, data)
  }
  // 获取用户信息
  static async getUserInfo(
    params: GetUserInfoParams
  ): PromiseResponse<UserInfo> {
    return service.post(`${BASE_URL}/userExpand/getUserInfo`, params)
  }
  // 修改用户信息
  static async updateAccountInfo(
    params: UpdateAccountInfoParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/userExpand/updateAccountInfo`, params)
  }
  // 上传头像
  static async uploadAvatar(
    params: UpdateAvatarParams
  ): PromiseResponse<UpdateAvatarData> {
    return service.post(`${BASE_URL}/userExpand/uploadAvatar`, params)
  }
  // 修改密码
  static async updateAccountPassword(
    params: UpdateAccountPasswordParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/userExpand/updateAccountPassword`, params)
  }
  // 设置邮箱
  static async updateWeb3Email(
    params: UpdateWeb3EmailParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/userExpand/updateWeb3Email`, params)
  }
  // 修改邮箱
  static async updateAccountEmail(
    params: UpdateAccountEmailParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/userExpand/updateAccountEmail`, params)
  }
  // 商城-获取门票列表
  static async getPointToNftList(
    params: GetPointToNftListParams
  ): PromiseResponse<HKTicket[]> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/pointsMall/getPointToNftList`, data)
  }
  // 商城-购买门票
  static async buyTicket(params: BuyTicketParams): PromiseResponse<string[]> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/pointsMall/summitBuy`, data)
  }
  // 商城-查询订单
  static async getTicketOrderList(
    params: GetOrderListParams
  ): PromiseResponse<TicketBuyRecord[]> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/pointsMall/getOrderList`, data)
  }
  // 商城-查询是否有订单在队列里排队
  static async getTicketTaskHandling(userId: number): PromiseResponse<boolean> {
    return service.post(`${BASE_URL}/pointsMall/getHandlerStatus`, { userId })
  }
  static async participateActivity(
    params: ActivityParticipateParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/pointsMall/participateActivity`, params)
  }
  static async getActivityAvailableBalance(
    params: ActivityAvailableBalanceParams
  ): PromiseResponse<number> {
    return service.post(
      `${BASE_URL}/pointsMall/getActivityAvailableBalance`,
      params
    )
  }
  static async logoff(): PromiseResponse<void> {
    return service.post(`${BASE_URL}/user/deleteUser`)
  }
  // 获取打call排行榜列表
  static async getRankingList(
    periods: number | string
  ): PromiseResponse<RankingListResult> {
    return await service.post(`${BASE_URL}/userExpand/getUsersCallRank`, {
      periods
    })
  }
}
